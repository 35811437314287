import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { MdLocalPhone, MdLocationOn, MdEmail } from "react-icons/md"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const SecondPage = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityCompanyInfo {
        name
        phone
        zipCode
        email
        address
        logo {
          asset {
            fluid(maxWidth: 600) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Kontakt Zignifikant" />

      <section className="contact">
        <div className="map">
          <iframe
            onLoad={test}
            title="Zignifikant-location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2219.491489049592!2d10.242573973680349!3d56.20046571615425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464c3e5efa7010e7%3A0x84859712fb986186!2sZignifikant%20Aps!5e0!3m2!1sda!2sdk!4v1583659656235!5m2!1sda!2sdk"
          ></iframe>
        </div>
        <div className="info">
          <h1>{data.sanityCompanyInfo.name}</h1>
          <div>
            {" "}
            <MdLocationOn />
            <p>{data.sanityCompanyInfo.address}</p>
            <div></div>
            <p>{data.sanityCompanyInfo.zipCode}</p>
          </div>
          <div>
            <MdLocalPhone />
            <p>{data.sanityCompanyInfo.phone}</p>
          </div>
          <div>
            <MdEmail />
            <p>{data.sanityCompanyInfo.email}</p>
          </div>
        </div>
      </section>
      <ContactForm></ContactForm>
    </Layout>
  )
}

function test() {
  document.querySelector("iframe").style.opacity = 1
}

export default SecondPage
